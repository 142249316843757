/* @font-face {
  font-family: "NotoSansJP";
  src: local("NotoSansJP"), local("NotoSansJP");
  src: url("../static/fonts/NotoSansJP/NotoSansJPRegular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansJP";
  src: local("NotoSansJP"), local("NotoSansJP");
  src: url("../static/fonts/NotoSansJP/NotoSansJPMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansJP";
  src: local("NotoSansJP"), local("NotoSansJP");
  src: url("../static/fonts/NotoSansJP/NotoSansJPBold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
} */

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: local("SVN-Gilroy"), local("SVN-Gilroy");
  src: url("../static/fonts/SVN/SVNGilroyLight.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SVN-Gilroy';
  src: local("SVN-Gilroy"), local("SVN-Gilroy");
  src: url("../static/fonts/SVN/SVNGilroyBold.woff") format('woff');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Noto Sans JP', sans-serif;
}

body, button {
  touch-action: manipulation;
}

html, body, #root {
  height: 100%;
}

/* @media (max-height: 540px) {
  html, body, #root {
    height: auto;
  }
} */